import dynamic from "next/dynamic";
import Seo from "../../components/common/Seo";
import Header10 from "../../components/header/header-10";
import Hero10 from "../../components/hero/hero-10";
import Blog1 from "../../components/blog/Blog1";
import LeSenegal from "../../components/home/home-10/LeSenegal";
import ParallaxBanner from "../../components/home/home-10/ParallaxBanner";
import LeMemorial from "../../components/home/home-10/LeMemorial";
import HsitoireDuMemorial from "../../components/home/home-10/HsitoireDuMemorial";
import Wave from '../../components/common/Wave';
import Wave2 from '../../components/common/Wave2';
import Wave3 from '../../components/common/Wave3';
import AboutSenegal from "../../components/home/home-10/AboutSenegal";
const home_10 = () => {
  return (
    <>
      <Seo pageTitle="Le Memorial" />
      {/* End Page Title */}
      {/* <Wave /> */}

      <Header10 />
      {/* End Header 5 */}

      <Hero10 />
      {/* End Hero 5 */}

      <section className="section-bg layout-pt-lg md:pt-0 md:pb-60 sm:pb-40">
        <HsitoireDuMemorial />
        <Wave2 />
      </section>

      <section
        className="layout-pt-md"
        style={{ backgroundColor: "#caf0f8" }}
      >
            <LeMemorial />
        <Wave />
      </section>

      {/* <ParallaxBanner /> */}

      {/* <div className="divider"></div> */}

      <section className="section-bg layout-pt-lg md:pt-0 md:pb-60 sm:pb-40"
      style={{backgroundColor: '#faf6f2'}}>
        {/* <LeSenegal /> */}
      <AboutSenegal />
        <Wave3 />
      </section>
      {/* End whycosse Section */}

      {/* <div className="divider"></div>*/}
      {/* End Parallax banner Section */}

      {/* <div className="divider"></div> */}

      <section className="layout-pt-md layout-pb-lg bg-black">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title text-white">Mediateque</h2>
                <p
                  className=" sectionTitle__text mt-5 sm:mt-0"
                  style={{ color: "#edc531" }}
                >
                  Elle est issue d&apos;un travail continu de recherche et de documentation
                </p>
              </div>
            </div>
          </div>
          <Blog1 />
        </div>
      </section>
      {/* End whycosse Section */}
    </>
  );
};

export default dynamic(() => Promise.resolve(home_10), { ssr: false });
