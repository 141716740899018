/* eslint-disable @next/next/no-img-element */
import Link from "next/link";

const PresentationJoola1 = () => {
  const imagePath = "/img/destinations/4/museum-joola.png";

  return (
    <div className="container" style={{ fontFamily: "Alegreya Sans" }}>
      <div className="row justify-center text-center">
        <div className="col-auto">
          <div className="sectionTitle -md">
            <p
              className="sectionTitle__text mt-5 sm:mt-0"
              style={{ fontFamily: "EB Garamond", color: "#1b4332" }}
            >
            Le JOOLA
            </p>
            <h2
              style={{ fontFamily: "EB Garamond", fontSize: "51px" }}
              className="sectionTitle__title"
            >
              Histoire du memorial
            </h2>
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-md-4">
          <p className="my-auto" data-aos="zoom-in-up" style={{textAlign:'justify'}} data-aos-delay="200">
          Le Bateau le JOOLA, Ferry mis en circulation en 1990 dans la liaison maritime Dakar-Ziguinchor pour développer la Casamance et maintenir la continuité territoriale de l’État du Sénégal fut un navire d’unité nationale. En 1985, lorsque la Casamance-express qui assurait la liaison maritime tombait en panne de façon récurant au point d’être mis au arrêt pour des raisons techniques, la commande d’un nouveau bateau s’est très vite imposée aux autorisés administratives pour desservir la Casamance et maintenir la circulation des personnes et de leurs biens. Le Président de la République à l’époque, S.E. Abdou DIOUF,  pris l’engagement de commander un nouveau bateau par un contrat de prêt entre le Gouvernement du Sénégal et l’Organisme de financement allemand KFW pour un coût global de 4 milliards de francs CFA. Il s’agissait d’un bâtiment constitué de 4 ponts pour accueillir 44 membres d’équipage et 536 passagers répartis entre 50 personnes en 1e classe, 150 personnes en 2e classe et 336 personnes en 3e classe. 
          </p>
          {/* <p className="my-auto"
                data-aos="zoom-in-down"
                data-aos-delay="200">
            Ce naufrage, l&apos;un des plus meurtriers de l&apos;histoire de la
            navigation civile maritime a fait plus de 1800 victimes de 14
            nationalité différentes. Il est resté un tmatisme national voire
            mondial.
          </p>rau
          <p className="my-auto"
                data-aos="zoom-in-up"
                data-aos-delay="200">
            Il fallait rester forts, unis, solidaires et lutter ensemble comme
            un seul peuple contre l&apos;oubli pour ainsi entretenir, dans la
            dignité et la prière, la mémoire de ces milliers de victimes.
          </p> */}
        </div>
        <div className="col-md-4">
          <div data-aos="fade-down-left" data-aos-delay="600">
            <img className="img-fluid" src={imagePath} alt="Le Joola" />
          </div>
        </div>
        <div className="col-md-4">
          {/* <p className="my-auto">
            En 2015, l&apos;Etat du Sénégal initie le projet de réalisation
            d&apos;un mémorial dédié aux victimes en réponse aux vielles
            doléances des familles de victimes.
          </p> */}
          <p className="my-auto" data-aos="flip-right" style={{textAlign:'justify'}} data-aos-delay="200">
          Le  navire a été lancé à l’origine sous le nom de Sangomar avant d’être rebaptisé Le Joola, puis livré officiellement à l’Etat du Sénégal le 12 novembre 1990 à Rotterdam (Pays-Bas). Son voyage inaugural a eu lieu le 14 décembre 1990 ou le navire a embarqué de Dakar une délégation d’autorités du Sénégal dirigée par le Ministre de l’Equipement, du Transport et du Logement, M. Robert SAGNA pour rallier Ziguinchor, son port d’attache. 
          </p>

          {/* <button className="button -outline-green-2 text-green-2 rounded-4 js-down p-3 mt-2">
            Voir plus
          </button> */}
              <div className="d-inline-block mt-40 lg:mt-30 sm:mt-20">
                <a
                  href="#"
                  className="button -md -blue-1 bg-yellow-1 text-dark-1"
                >
                  Lire plus <div className="icon-arrow-top-right ml-15" />
                </a>
              </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationJoola1;
