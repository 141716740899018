// Importez les bibliothèques nécessaires
import React, { useEffect } from "react";
import Image from "next/image";
import AOS from "aos";
import "aos/dist/aos.css";

import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';


const HistoireDuMemorial = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh(); // Rafraîchir AOS après l'initialisation
  }, []);

  return (
    <div className="container">
      <div id="NomDeLAncre" className="row">
        <div
          className="col-xl-4 col-md-12"
          data-aos="fade-right"
          data-aos-delay="400"
          data-aos-anchor-placement="center-bottom"
        >
          <Image
            width={450}
            height={350}
            src="/img/backgrounds/memorial-le-joola-home.png"
            alt="image"
            className="rounded-16"
          />
        </div>
        {/* End left photo */}

        <div className="col-xl-8 col-md-5 order-1 order-md-2"
          data-aos="flip-up"
          data-aos-anchor-placement="center-bottom">
          <h2 className="text-30 fw-600 text-mem-black">
            Nous sommes au Sénégal
          </h2>
          {/* <h3>Introduction</h3> */}
          <Typist>
            <p className="mt-2" style={{ textAlign: 'justify' }}>
              Situé en Afrique de l&apos;Ouest, sur la partie la plus avancée de l&apos;Afrique dans l&apos;Océan Atlantique, le Sénégal est un vrai Finistère, au sud du Sahara. Il est réputé pour sa riche histoire, sa culture diversifiée, ses paysages naturels époustouflants, son hospitalité.
              Il tient son nom du Fleuve qui fait l&apos;essentiel de sa frontière Nord et Est, entre la Mauritanie et le Mali. Ce fleuve, davantage qu&apos;une frontière, est un liant qui met en lumière les continuités géographiques, historiques et culturelles, significatives d&apos;une communauté dont l&apos;harmonie couvre des espaces qui vont bien plus loin que ne suggèrent les découpages des états. Mais les continuités sont soutenues par des diversités fécondes qui sont des nuances que suggèrent l&apos;environnement et les activités des femmes et des hommes sommés toujours d&apos;inventer leur être-au-monde et leur devenir.
            </p>
          </Typist>
        </div>
        {/* End right text */}
      </div>
    </div>
  );
};

export default HistoireDuMemorial;
